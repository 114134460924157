export const WAREHOUSE_FIELD = 'warehouse_id'
export const LOCATION_TYPE_FIELD = 'location_type_id'
export const STORAGE_TYPE_FIELD = 'storage_type_id'
export const AISLE_FIELD = 'aisle_id'
export const BAY_FIELD = 'bay_id'
export const LEVEL_FIELD = 'level'
export const DEPTH_FIELD = 'pushback'
export const DOCK_FIELD = 'dock_id'
export const LENGTH_FIELD = 'length'
export const WIDTH_FIELD = 'width'
export const HEIGHT_FIELD = 'height'
export const IS_PERMANENT_FIELD = 'is_permanent'
export const WRAP_REQUIRED_FIELD = 'wrap_required'
export const BAY_RANGE_TYPE_FIELD = 'bay_range_type'
export const BAY_RANGE_SWITCH_FIELD = 'bay_range_switch'

export const CONTAINER_NAME = 'name'
export const CONTAINER_PRIORITY_FIELD = 'container_priority'
export const VENDOR_FIELD = 'supplier_id'
export const CONTAINER_STATUS_FIELD = 'container_status_id'
export const CONTAINER_EXPECTED_ARRIVE_DATE_FIELD = 'should_arrive_at'
export const CONTAINER_ARRIVE_DATE_FIELD = 'arrive_at'
export const CONTAINER_CASES_QTY_FIELD = 'invoice_cases_quantity'
export const CONTAINER_PALLETS_QTY_FIELD = 'invoice_pallets_quantity'

export const PRODUCT_MODEL = 'products_model'
export const PRODUCT_MANF_MODEL = 'products_manf_model'
export const PRODUCT_NAME = 'products_name'

export const CONTAINER_PRODUCTS = 'container_products'
export const CONTAINER_PRODUCTS_QTY = 'quantity'

export const REGULAR_LOCATION = 'regular_location'
export const DOCK_LOCATION = 'dock_location'
export const WAREHOUSE = 'warehouse'
export const LOCATION_TYPE = 'location_type'
export const STORAGE_TYPE = 'storage_type'
export const CONTAINER_STATUS = 'container_status'
export const VENDOR = 'vendor'
export const PRODUCT = 'product'
