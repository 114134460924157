import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import BarcodeScanner from 'components/common/BarcodeScanner'
import { fetchInventoryCountStart } from 'store/homeAnalytics/actions'
import {
  clearInventoryLocation,
  fetchInventoryLocationStart,
  fetchPalletStart,
  savePalletStart,
  deletePalletStart,
  fetchProductStart,
  saveProductStart,
  resetInventoryLocationStart,
  copyProductToStageStart,
} from 'store/inventory/actions'
import Header from 'components/common/Header'
import { redirect } from 'helpers/index'
import './style.scss'
import { resetModals, showModal } from 'store/modal/actions'
import { SNACK_SUCCESS, SNACK_ERROR, SNACK_INFO } from 'constants/snackbar'
import { useSnackbar } from 'notistack'
import Badge from '@material-ui/core/Badge'
import Button from 'components/common/Button'
import { MdDelete } from 'react-icons/md'
import { HOME_PAGE } from 'constants/routes'
import InputQuantityControl from 'components/common/InputQuantityControl'
import { MODAL_INPUT_QUANTITY_CONTROL } from 'components/common/Modal'

const InventoryPage = (props) => {
  const {
    clearInventoryLocation,
    fetchInventoryCountStart,
    fetchInventoryLocationStart,
    resetInventoryLocationStart,
    copyProductToStageStart,
    fetchPalletStart,
    savePalletStart,
    deletePalletStart,
    fetchProductStart,
    saveProductStart,
    showModal,
    resetModals,
    warehouseLocation,
    pallet,
    inventoryCount,
  } = props

  const [quantity, setQuantity] = useState(0)
  const [isLocationScanned, setLocationScanned] = useState(false)
  // const [isCaseScanned, setCaseScanned] = useState(false)
  const [isScannerActive, setScannerActive] = useState(true)
  const [onScannerEnter, setOnScannerEnter] = useState(() => {})
  const [isProductSubmit, setProductSubmit] = useState(false)
  const [isPalletSubmit, setPalletSubmit] = useState(false)

  const quantityStyles = {
    fontSize: 32,
  }
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    reloadAnalytics()
    resetModals()

    //for some reason the following fixes a first time scan bug
    setTimeout(() => {
      showModal({
        name: 'modalPending',
      })
      setTimeout(() => {
        resetModals()
      }, 100)
    }, 100)

    return () => clearTimeout(timeoutId)
  }, [])

  let timeoutId
  const reloadAnalytics = () => {
    timeoutId = setTimeout(() => {
      fetchInventoryCountStart()
      reloadAnalytics()
    }, 30000)
  }

  const productOnEnter = () => {
    setProductSubmit(true)
  }

  useEffect(() => {
    if (isProductSubmit) {
      setProductSubmit(false)
      onDone()
    }
  }, [isProductSubmit])

  const palletOnEnter = () => {
    setPalletSubmit(true)
  }

  useEffect(() => {
    if (isPalletSubmit) {
      resetModals()
      setPalletSubmit(false)
      handlePalletCaseQuantity(quantity)
    }
  }, [isPalletSubmit])

  useEffect(() => {
    if (pallet && pallet.is_partial) {
      setTimeout(() => {
        setScannerActive(false)
        setOnScannerEnter(() => palletOnEnter)
        showModal({
          name: MODAL_INPUT_QUANTITY_CONTROL,
          onDeny: resetModals,
          onConfirm: handlePalletCaseQuantity,
          onChange: handlePalletCaseQuantityChange,
          value: 0,
          maxValue: 999,
          className: '',
          // allCaseQuantity: getAllCaseQuantity(),
        })
      }, 200)
      enqueueSnackbar('Partial Pallet. Enter Case Count', SNACK_INFO)
    }
  }, [pallet])

  const handlePalletCaseQuantityChange = (palletQty) => {
    setQuantity(palletQty)
  }

  const fetchLocationAfterAction = (data) => {
    resetModals()
    if (data.success) {
      setLocationScanned(true)
      if (data.location.is_pick && data.location.warehouse_case) {
        setQuantity(data.location.warehouse_case.location_case_qty)
        setScannerActive(false)
        setOnScannerEnter(() => productOnEnter)
      }
    } else {
      enqueueSnackbar(data.message, SNACK_ERROR)
    }
  }

  const fetchPalletAfterAction = (data) => {
    resetModals()
    if (!data.success) {
      enqueueSnackbar(data.message, SNACK_ERROR)
    } else {
      if (data.location.is_full) {
        setLocationScanned(false)
        setQuantity(0)
        clearInventoryLocation()
        fetchInventoryCountStart()
      }
    }
  }

  const handlePalletCaseQuantity = (qty_cases_on_pallet) => {
    setScannerActive(true)
    setOnScannerEnter(() => {})
    resetModals()
    setTimeout(() => {
      showModal({
        name: 'modalPending',
      })
    }, 100)

    savePalletStart({
      location_upc: warehouseLocation.upc,
      data: {
        warehouse_case_id: warehouseLocation.warehouse_case.id,
        id: pallet.id_stage,
        qty_cases_on_pallet: qty_cases_on_pallet,
      },
      afterAction: () => {
        resetModals()
      },
    })
  }

  const fetchProductAfterAction = (data) => {
    resetModals()
    if (!data.success) {
      setQuantity(data.location.location_case_qty)
      enqueueSnackbar(data.message, SNACK_ERROR)
    }
  }

  const onScan = (value) => {
    if (!value) {
      return
    }

    showModal({
      name: 'modalPending',
    })

    if (isLocationScanned) {
      if (warehouseLocation.is_pick) {
        fetchProductStart({
          upc: value,
          location_upc: warehouseLocation.upc,
          afterAction: fetchProductAfterAction,
        })
      } else {
        fetchPalletStart({
          upc: value,
          location_upc: warehouseLocation.upc,
          afterAction: fetchPalletAfterAction,
        })
      }
    } else {
      fetchInventoryLocationStart({
        upc: value,
        afterAction: fetchLocationAfterAction,
      })
    }
  }

  const onDelete = (value) => {
    deletePalletStart({
      upc: value,
      location_upc: warehouseLocation.upc,
      afterAction: fetchPalletAfterAction,
    })
  }

  const onDone = () => {
    setScannerActive(true)
    setOnScannerEnter(() => {})

    if (warehouseLocation.is_pick && warehouseLocation.warehouse_case) {
      saveProductStart({
        location_upc: warehouseLocation.upc,
        data: {
          id: warehouseLocation.warehouse_case.id,
          location_case_qty: quantity,
        },
        afterAction: () => {
          setLocationScanned(false)
          setQuantity(0)
          clearInventoryLocation()
          fetchInventoryCountStart()
        },
      })
    } else if (
      warehouseLocation.is_pick &&
      warehouseLocation.existing_products_id
    ) {
      copyProductToStageStart({
        upc: warehouseLocation.upc,
        afterAction: () => {
          setLocationScanned(false)
          setQuantity(0)
          clearInventoryLocation()
        },
      })
    } else {
      setLocationScanned(false)
      setQuantity(0)
      clearInventoryLocation()
      fetchInventoryCountStart()
    }
  }

  const onReset = () => {
    setScannerActive(true)
    setOnScannerEnter(() => {})

    resetInventoryLocationStart({
      upc: warehouseLocation.upc,
      afterAction: () => {
        setLocationScanned(false)
        setQuantity(0)
        clearInventoryLocation()
      },
    })
  }

  const onIncrement = () => setQuantity(quantity + 1)
  const onDecrement = () => setQuantity(quantity - 1)
  const onChange = (name, value) => {
    setQuantity(value)
  }

  const locationInfo = warehouseLocation?.id ? (
    <>
      <div className="inv-taker-aisle-bay-level">
        {warehouseLocation.aisle_bay_level}{' '}
        {warehouseLocation.warehouse_case ? (
          <span className="location-case-qty">
            ({warehouseLocation.warehouse_case.location_case_qty})
          </span>
        ) : (
          <></>
        )}
      </div>
      {warehouseLocation.warehouse_case ? (
        <div>
          <div className="inv-taker-products-model">
            {warehouseLocation?.warehouse_case?.products_model}{' '}
            <span className="inv-taker-products-manf-model">
              ({warehouseLocation?.warehouse_case?.products_manf_model})
            </span>
          </div>
          <div className="inv-taker-products-name">
            {warehouseLocation.warehouse_case.products_name}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  ) : (
    <div className="scan-location">Scan Location</div>
  )

  return (
    <div className="inventory-page">
      <Header>
        {warehouseLocation.id ? (
          <div className="reset-button" onClick={onReset}>
            Reset
          </div>
        ) : (
          <div className="reset-button-blank">&nbsp;</div>
        )}

        <div className="title">
          <Badge
            badgeContent={inventoryCount}
            max={10000}
            color="error"
            overlap="rectangular"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            Inventory
          </Badge>
        </div>
        <div
          className="close-button"
          onClick={() => {
            onDone()
            redirect(HOME_PAGE)
          }}
        >
          Close
        </div>
      </Header>
      <div className="content">
        <BarcodeScanner
          needToRemoveListener={false}
          onReceiveData={onScan}
          active={isScannerActive}
          onScannerEnter={onScannerEnter}
        />
        {locationInfo}
        {warehouseLocation.is_pick ? (
          <div className="pick-data">
            {warehouseLocation?.warehouse_case ? (
              <div>
                <InputQuantityControl
                  minValue={0}
                  maxValue={9999}
                  onChange={onChange}
                  value={quantity}
                  onDecrease={onDecrement}
                  onIncrease={onIncrement}
                  styles={quantityStyles}
                />
              </div>
            ) : (
              <div>Scan Case</div>
            )}
          </div>
        ) : (
          <div className="scanned-pallets">
            {warehouseLocation.pallet_slots?.map((pallet_slot, index) => (
              <div
                key={index}
                className={`pallet-slot ${
                  pallet_slot.pallet?.is_partial ? 'partial-pallet' : ''
                }`}
              >
                {pallet_slot.pallet ? (
                  <>
                    <div
                      className={`${
                        pallet_slot.pallet.is_partial ? 'partial-pallet' : ''
                      }`}
                    >
                      {pallet_slot.pallet?.pallet_upc} -{' '}
                      {pallet_slot.pallet?.qty_cases_on_pallet}
                    </div>
                    <div
                      className="delete-button"
                      onClick={() => {
                        onDelete(pallet_slot.pallet?.pallet_upc)
                      }}
                    >
                      <MdDelete />
                    </div>
                  </>
                ) : (
                  '<scan pallet>'
                )}
              </div>
            ))}
          </div>
        )}

        {isLocationScanned ? (
          <div className="save-button-div">
            <Button className="save-button" onClick={onDone}>
              {warehouseLocation?.is_pick && warehouseLocation.warehouse_case
                ? 'Save'
                : 'Done'}
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  warehouseLocation: state.inventoryReducer.warehouseLocation,
  pallet: state.inventoryReducer.pallet,
  inventoryCount: state.homeAnalyticsReducer.inventoryCount,
})

const mapDispatchToProps = {
  clearInventoryLocation,
  fetchInventoryCountStart,
  fetchInventoryLocationStart,
  resetInventoryLocationStart,
  copyProductToStageStart,
  fetchPalletStart,
  savePalletStart,
  deletePalletStart,
  fetchProductStart,
  saveProductStart,
  showModal,
  resetModals,
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryPage)
